'use strict';

/*******************************************************************************************/
class UxrPopupContent20Event extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
		};
		
		this.onJQueryMount = this.onJQueryMount.bind(this);
	}
	
	/*---------------------------------------------------------------------*
	renderLink(model) {
		if (model.url) {
			return (<a target="blank" href={model.url} key={model.url}>{model.text}</a>);
		} else {
			return (<span key={model.text}>{model.text}</span>);
		}
	}
	/*---------------------------------------------------------------------*
	renderList(models) {
		if (!models || !(models instanceof Array)) {
			return null;
		}
		
		let o = this;
		
		let result = [];
		_.forEach(models, function(each, i) {
			if (each === null) {
				return;
			}
			result.push(each);
			result.push(<br key={i} />);
		});
		result.pop();
		return result;
	}
	/*---------------------------------------------------------------------*
	renderLinkList(models) {
		if (!models || !(models instanceof Array)) {
			return null;
		}
		return this.renderList(models.map(this.renderLink));
	}
	
	/*---------------------------------------------------------------------*/
	renderInfoSection(test, title, content, treatAsHtml, langLabels) {
		var o = this;
		
		if (title) {
			title = title.split("/");
			title = (
				<h3>
					{UxrRenderDuoLangLabel(title[0], title[1])}
				</h3>
			);
		}
		
		if (test) {
			switch (typeof(test)) {
				case "string":
					if (test.length===0) {
						return null;
					}
					break;
				case "object":
					if (test instanceof Array) {
						if (test.length===0) {
							return null;
						}
					} else {
						if (true
							&& (!('de' in test) || !test.de || test.de.length===0)
							&& (!('en' in test) || !test.en || test.en.length===0))
						{
							return null;
						}
					}
				
					break;
				default:
					console.error(test);
					return null;
			}
		} else {
			return null;
		}
			
		let contentDe = null;
		let contentEn = null;
		
		if (typeof(content) === "string") {
			contentDe = content;
		} else {
			contentDe = content.de;
			if (content.en && content.en.length > 0) {
				contentEn = content.en;
			}
		}
		
		if (treatAsHtml) {
			contentDe = (<div dangerouslySetInnerHTML={{__html:contentDe}} />);
			if (contentEn) {
				contentEn = (<div dangerouslySetInnerHTML={{__html:contentEn}} />);
			}
		} else {
			contentDe = (<div>{contentDe}</div>);
			if (contentEn) {
				contentEn = (<div>contentEn}</div>);
			}
		}
			
		if (langLabels && langLabels.de) {
			contentDe = (
				<div>
					<div className="languageMarkerLabel">{langLabels.de}</div>
					{contentDe}
				</div>
			);
		}
			
		if (contentEn) {
			if (langLabels && langLabels.en) {
				contentEn = (
					<div>
						<div style={{paddingTop: '0.6em'}} />
						<div className="languageMarkerLabel">{langLabels.en}</div>
						{contentEn}
					</div>
				);
			} else {
				contentEn = (
					<div>
						<div style={{paddingTop: '0.6em'}} />
						{contentEn}
					</div>
				);
			}
		}
				
		return (
			<div>
				{title}
				{contentDe}
				{contentEn}
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let o = this;
		
		let p = GLOBALS.popupsData[this.props.popupId];
		let r = {};
		
		var shareUrl = encodeURIComponent("http://remembering-leipzig.de#!" + p.id);
		var shareButtons = (
			<div className="socials">
				<a className="socials-download" download href={p.audio.link}><div className="fa fa-download"></div></a>
				<a className="socials-facebook" target="_blank" href={'http://www.facebook.com/sharer/sharer.php?u='+ shareUrl}><div className="fa fa-facebook-square"></div></a>
				<a className="socials-twitter" target="_blank" href={"https://twitter.com/intent/tweet?url="+ shareUrl}><div className="fa fa-twitter-square"></div></a>
			</div>
		);
	
		if (p.audio) {
			let sources = p.info.sources;
			let furtherInfo = p.info.furtherInfo;
			
			r.controlsAudio = (
				<div className="popupContainer_tab" data-contenttype=".content_audio">
					<div className="fa fa-volume-up"></div>
				</div>
			);
			
			/*
			let deutcherText = (
				<div>
					<div className="languageMarkerLabel">Zitat</div>
					<div dangerouslySetInnerHTML={{__html:p.audio.textDe}} />
				</div>
			);
			let englischerText = p.audio.textEn? (
				<div>
					<div style={{paddingTop: '0.6em'}} />
					<div className="languageMarkerLabel">Quotation</div>
					<div dangerouslySetInnerHTML={{__html:p.audio.textEn}} />
				</div>
				): null;
				*/
			let audioText = {
				"de": p.audio.textDe,
				"en": p.audio.textEn,
			};
				
			r.contentAudio = (
				<div className="contentTab content_audio">
					<div className="verticalSpace size09"></div>
					<div className="">
						{p.info.name}
					</div>
					<div className="">
						{p.audio.originTime ? p.audio.originTime : null}
						{", "}
						{p.audio.originPlace}
						{p.audio.originPlaceHistorical ? (' (damals '+ p.audio.originPlaceHistorical+ ')'): null}
					</div>
					<div className="verticalSpace size09"></div>
					<div className="size12">{p.audio.originPlaceTime}</div>
					<audio data-src={p.audio.link} controls="controls"></audio>
					<div className="size12">
						{UxrRenderDuoLangLabel("Sprecher/in", "Speaker")}
						{": "}
						{p.audio.speakers.join(', ')}{p.audio.speakerPlaceTime? ' ('+ p.audio.speakerPlaceTime+ ')' : ''}
					</div>
					<div className="flowRight writeCaps">audio</div>
					<div className="flowClear"></div>
					<div className="scrollable">
						{this.renderInfoSection(audioText, null, audioText, 'html', {"de":"Zitat", "en":"Quotation"})}
						{this.renderInfoSection(sources, 'Quelle/Origin', sources, 'html')}
						{this.renderInfoSection(furtherInfo, 'weitere Informationen/Further Information', furtherInfo, 'html')}
					</div>
				</div>
			);
		} //audio
		
		if (p.info) {
			
			let name = p.info.name;
			let born = p.info.born;
			let died = p.info.died;
			let stagesOfLife = p.info.stagesOfLife;
			let peers = p.info.peers;
			let sources = p.info.sources;
			let contact = (<a href="mailto:projekt@remembering-leipzig.de">projekt@remembering-leipzig.de</a>);
			
			let lifeInLeipzig = {
				"de": p.info.lifeInLeipzigDe,
				"en": p.info.lifeInLeipzigEn,
			};
			
			
			r.controlsInfo = (
				<div className="popupContainer_tab" data-contenttype=".content_info">
					<div className="fa fa-info-circle"></div>
				</div>
			);
			
			r.contentInfo = (
				<div className="contentTab content_info">
					<div className="flowRight writeCaps">info</div>
					<div className="flowClear"></div>
					<div className="scrollable">
					
						{this.renderInfoSection(name, 'Name/name', name)}
						{this.renderInfoSection(born, 'geboren/born', born)}
						{this.renderInfoSection(died, 'gestorben/died', died)}
						{this.renderInfoSection(lifeInLeipzig, 'Leben in Leipzig/The Life in Leipzig', lifeInLeipzig, 'html', {"de":"Leben in Leipzig", "en":"The Life in Leipzig"})}
						{this.renderInfoSection(stagesOfLife, 'weitere Lebensstationen/Further Stages of Life', stagesOfLife, 'html')}
						{this.renderInfoSection(peers, 'Verwandtschaft/Family', peers, 'html')}
						{this.renderInfoSection(sources, 'Quelle/Origin', sources, 'html')}
						{this.renderInfoSection(contact, 'Kontakt zu Einreichern/Submitter\'s Contact', contact)}
						
					</div>
				</div>
			);
		} //info
		
		if (p.media) {
			let media = [];
			_.forEach(p.media, function(eachMedia, i) {
				media.push(
					<div key={i} className="popup_media_item">
						<img data-src={eachMedia.urlPreview} data-src-overlay={eachMedia.url} alt={eachMedia.signature} title={eachMedia.signature} />
					</div>
				);
			});
			
			r.controlsMedia = (
				<div className="popupContainer_tab" data-contenttype=".content_images">
					<div className="fa fa-picture-o"></div>
				</div>
			);
			r.contentMedia = (
				<div className="contentTab content_images">
					<div className="flowRight writeCaps">media</div>
					<div className="flowClear"></div>
					<div className="scrollable">
						{media}
					</div>
				</div>
			);
		} //media
		
		let props = {};
		props['data-id'] = this.props.popupId;
		props['ref'] = this.onJQueryMount;
		
		return (
			<div className="popupContainer" {...props}>
				<div className="popupContainer_controls">
					
					{r.controlsAudio}
					{r.controlsInfo}
					{r.controlsMedia}
					
					<div className="popupContainer_iconClose fa fa-close"></div>
					<div className="popupContainer_iconNext fa fa-angle-right writeBold"></div>
					<div className="popupContainer_iconPrev fa fa-angle-left writeBold"></div>
					<div className="flowClear"></div>
				</div>
				
				<div className="popupContainer_content">
					<h2 className="title">{p.title}</h2>
					<div className="content">
						{r.contentAudio}
						{r.contentInfo}
						{r.contentMedia}
						{shareButtons}
					</div>
				</div>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	componentDidMount() {
	}
	
	/*---------------------------------------------------------------------*/
	shouldComponentUpdate(nextProps, nextState) {
		return false;
	}
	
	/*---------------------------------------------------------------------*/
	onJQueryMount(node) {
		this.jQ = jQuery(node);
		let p = GLOBALS.popupsData[this.props.popupId];
		p.fulltext = this.jQ.text();
		p.jQ = this.jQ;
	}
	
} //class
